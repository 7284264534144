import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["pageNotice", "form", "firstName", "lastName", "email", "message"]

    sentMessage() {
        this.formTarget.classList.remove('was-validated')
        this.formTarget.classList.remove('valid')
        this.firstNameTarget.value = '';
        this.lastNameTarget.value = '';
        this.emailTarget.value = '';
        this.messageTarget.value = '';

        this.displayMessage(this.pageNoticeTarget)
    };

    displayMessage(messageContainer) {
        $(messageContainer).removeClass('d-none');
        setTimeout(() => {
            $(messageContainer).addClass('d-none');
        }, 5000);
    }

    hideMessageContainers() {
        $(this.pageNoticeTarget).addClass('d-none');
    }
}

